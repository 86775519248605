<template>
  <div>
    <base-page-heading title="Adaugare lucrare" subtitle="Adaugare si setare lucrare noua">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Lucrari</b-breadcrumb-item>
          <b-breadcrumb-item active>Lucrare nou</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row class="row-deck">
        <b-col xl="12">
          <base-block>
            <b-form @submit.stop.prevent="onAddWorkSubmit">
              <b-row>
                <b-col xl="12">
                  <base-block title="Introduce datele lucrarii" header-bg content-full>
                    <div class="font-size-sm push">
                      <div class="row">
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="clientName">
                            <b-form-input size="lg" class="form-control-alt" id="clientName" name="clientName" placeholder="Nume client" v-model="$v.addWorkForm.clientName.$model" :state="!$v.addWorkForm.clientName.$error && (!errors.addWorkForm.clientName) && null" aria-describedby="clientName-feedback" @blur="removeServerErrors('addWorkForm')" @input="removeServerErrors('addWorkForm')"></b-form-input>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.clientName.required" id="clientName-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.clientName.minLength" id="clientName-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.addWorkForm.clientName" v-text="errors.addWorkForm.clientName" id="clientName-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="clientPhone">
                            <b-form-input size="lg" class="form-control-alt" id="clientPhone" name="clientPhone" placeholder="Telefon" v-model="$v.addWorkForm.clientPhone.$model" :state="!$v.addWorkForm.clientPhone.$error && (!errors.addWorkForm.clientPhone) && null" aria-describedby="clientPhone-feedback" @blur="removeServerErrors('addWorkForm')" @input="removeServerErrors('addWorkForm')"></b-form-input>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.clientPhone.required" id="clientPhone-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.clientPhone.minLength" id="clientPhone-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.addWorkForm.clientPhone" v-text="errors.addWorkForm.clientPhone" id="clientPhone-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="address">
                            <b-form-input size="lg" class="form-control-alt" id="address" name="address" placeholder="Adresa (optional)" v-model="$v.addWorkForm.address.$model" :state="!$v.addWorkForm.address.$error && (!errors.addWorkForm.address) && null" aria-describedby="address-feedback" @blur="removeServerErrors('addWorkForm')" @input="removeServerErrors('addWorkForm')"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="email">
                            <b-form-input size="lg" class="form-control-alt" id="email" name="email" placeholder="Email (optional)" v-model="$v.addWorkForm.email.$model" :state="!$v.addWorkForm.email.$error && (!errors.addWorkForm.email) && null" aria-describedby="email-feedback" @blur="removeServerErrors('addWorkForm')" @input="removeServerErrors('addWorkForm')"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="productName">
                            <b-form-input size="lg" class="form-control-alt" id="productName" name="productName" placeholder="Denumire echipament" v-model="$v.addWorkForm.productName.$model" :state="!$v.addWorkForm.productName.$error && (!errors.addWorkForm.clientPhone) && null" aria-describedby="productName-feedback" @blur="removeServerErrors('addWorkForm')" @input="removeServerErrors('addWorkForm')"></b-form-input>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.productName.required" id="productName-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.productName.minLength" id="productName-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.addWorkForm.productName" v-text="errors.addWorkForm.productName" id="productName-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="productSeries">
                            <b-form-input size="lg" class="form-control-alt" id="productSeries" name="productSeries" placeholder="Serie echipament" v-model="$v.addWorkForm.productSeries.$model" :state="!$v.addWorkForm.productSeries.$error && (!errors.addWorkForm.clientPhone) && null" aria-describedby="productSeries-feedback" @blur="removeServerErrors('addWorkForm')" @input="removeServerErrors('addWorkForm')"></b-form-input>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.productSeries.required" id="productSeries-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.productSeries.minLength" id="productSeries-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.addWorkForm.productSeries" v-text="errors.addWorkForm.productSeries" id="productSeries-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-6">
                          <b-form-group class="form-group" label-for="productDescription">
                            <b-form-textarea size="lg" class="form-control-alt" id="productDescription" name="productDescription" placeholder="Detalii echipament" v-model="$v.addWorkForm.productDescription.$model" :state="!$v.addWorkForm.productDescription.$error && (!errors.addWorkForm.clientPhone) && null" aria-describedby="productDescription-feedback" @blur="removeServerErrors('addWorkForm')" @input="removeServerErrors('addWorkForm')"></b-form-textarea>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.productDescription.required" id="productDescription-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.productDescription.minLength" id="productDescription-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.addWorkForm.productDescription" v-text="errors.addWorkForm.productDescription" id="productDescription-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-6">
                          <b-form-group class="form-group" label-for="problemDescription">
                            <b-form-textarea size="lg" class="form-control-alt" id="problemDescription" name="problemDescription" placeholder="Descrierea problemei" v-model="$v.addWorkForm.problemDescription.$model" :state="!$v.addWorkForm.problemDescription.$error && (!errors.addWorkForm.clientPhone) && null" aria-describedby="problemDescription-feedback" @blur="removeServerErrors('addWorkForm')" @input="removeServerErrors('addWorkForm')"></b-form-textarea>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.problemDescription.required" id="problemDescription-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.problemDescription.minLength" id="problemDescription-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.addWorkForm.problemDescription" v-text="errors.addWorkForm.problemDescription" id="problemDescription-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4">
                          <b-form-group class="form-group" label-for="resolveTermDate">
                            <flat-pickr size="lg" id="resolveTermDate" v-bind:class="{'is-invalid': $v.addWorkForm.resolveTermDate.$error || errors.addWorkForm.resolveTermDate}" class="form-control form-control-alt" placeholder="Termen de rezolvare" v-model="$v.addWorkForm.resolveTermDate.$model" :config="datepickerConfig" :state="!$v.addWorkForm.resolveTermDate.$error && (!errors.addWorkForm.clientPhone) && null" aria-describedby="resolveTermDate-feedback" @blur="removeServerErrors('addWorkForm')" @input="removeServerErrors('addWorkForm')"></flat-pickr>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.resolveTermDate.required" id="resolveTermDate-feedback">
                              Campul este obligatoriu!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="!$v.addWorkForm.resolveTermDate.minLength" id="resolveTermDate-feedback">
                              Campul trebuie sa aiba minimum 3 caractere!
                            </b-form-invalid-feedback>
                            <b-form-invalid-feedback v-if="errors.addWorkForm.resolveTermDate" v-text="errors.addWorkForm.resolveTermDate" id="resolveTermDate-feedback"></b-form-invalid-feedback>
                          </b-form-group>
                        </div>
                        <div class="col-sm-4 mt-2">
                          <b-form-checkbox name="warranty" v-model="$v.addWorkForm.warranty.$model" switch stacked>
                            Echipamentul este in garantie
                          </b-form-checkbox>
                        </div>
                        <div class="col-sm-2 offset-sm-2 mt-2">
                            <b-button type="submit" variant="primary" block>
                              <i class="fa fa-fw fa-plus-circle mr-1"></i> Adauga
                            </b-button>
                        </div>
                      </div>
                    </div>
                  </base-block>
                </b-col>
              </b-row>
            </b-form>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss">
// Flatpickr + Custom overrides
@import '~flatpickr/dist/flatpickr.css';
@import './src/assets/scss/vendor/flatpickr';
</style>

<script>
import {validationMixin} from "vuelidate";
import {minLength, required} from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import {fleshErrorMessage, handleFormValidation} from "@/error/server-error";
import {CREATE_SERVICE_WORK} from "@/constants/service-model-graphql";

export default {
  name: "ServiceWorkAdd",
  components: {flatPickr},
  mixins: [validationMixin],
  data () {
    return {
      datepickerConfig: {
        minDate: 'today',
      },
      addWorkForm: {
        clientName: null,
        clientPhone: null,
        address: null,
        email: null,
        productName: null,
        productSeries: null,
        productDescription: null,
        problemDescription: null,
        resolveTermDate: null,
        warranty: null,
      },
      errors: {
        addWorkForm: {},
      },
    }
  },
  validations: {
    addWorkForm: {
      clientName: {
        required,
        minLength: minLength(3)
      },
      clientPhone: {
        required,
        minLength: minLength(3)
      },
      address: {},
      email: {},
      productName: {
        required,
        minLength: minLength(3)
      },
      productSeries: {
        required,
        minLength: minLength(3)
      },
      productDescription: {
        required,
        minLength: minLength(3)
      },
      problemDescription: {
        required,
        minLength: minLength(3)
      },
      resolveTermDate: {
        required,
        minLength: minLength(3)
      },
      warranty: {},
    }
  },
  methods: {
    onAddWorkSubmit() {
      this.errors.addWorkForm = {};
      this.$v.addWorkForm.$touch()
      if (this.$v.addWorkForm.$anyError) {
        return;
      }
      this.$apollo.mutate({
        mutation: CREATE_SERVICE_WORK,
        variables: {
          'clientName': this.$v.addWorkForm.clientName.$model,
          'clientPhone': this.$v.addWorkForm.clientPhone.$model,
          'clientAddress': this.$v.addWorkForm.address.$model,
          'clientEmail': this.$v.addWorkForm.email.$model,
          'productName': this.$v.addWorkForm.productName.$model,
          'productSeries': this.$v.addWorkForm.productSeries.$model,
          'productDescription': this.$v.addWorkForm.productDescription.$model,
          'problemDescription': this.$v.addWorkForm.problemDescription.$model,
          'resolveTerm': this.$v.addWorkForm.resolveTermDate.$model,
          'isInWarranty': this.$v.addWorkForm.warranty.$model,
        }
      }).then(() => {
        this.flashMessage.info({message: "Lucrare adaugata cu succes!"});
        this.$router
            .push('/service/list')
            .catch(error => {
              error = {};
              fleshErrorMessage(error, this);
            });
      }).catch((error) => {
        let form = this.$v.addWorkForm;
        this.errors.addWorkForm = handleFormValidation(error, form);
        fleshErrorMessage(error, this);
      })
    },
    removeServerErrors(form) {
      this.errors[form] = {};
    }
  }
}
</script>